import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import AboutProjectComponent from "../components/about-project/about-project.component"

const AboutProject = () => {
  return (
    <Layout>
      <AboutProjectComponent />
    </Layout>
  )
}

export default AboutProject

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
